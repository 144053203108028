import { useEffect } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { SeeMoreCoursesButton } from '../../styles/HomeStyles';
import { useNavigate } from 'react-router-dom';

import { useInstitutionalContext } from '../../context/InstitutionalContext';
import { MainJumbotron } from './MainJumbotron';
import { WhoWeAreComponent } from './WhoWeAreComponent';
import { CourseMobileCarrouselComponent } from './CourseMobileCarrouselComponent';
import { OurActivitiesComponent } from './OurActivitiesComponent';
import { WhatWeOfferComponent } from './WhatWeOfferComponent';
import { PartnersComponent } from './PartnersComponent';

export function HomeComponent() {
  const navigate = useNavigate();

  const { institutionalPageInfo, courses, getHomeCourses } =
    useInstitutionalContext();

  useEffect(() => {
    getHomeCourses();
  }, []);

  if (institutionalPageInfo === undefined) {
    return <></>;
  }

  function redirectTo(path: string) {
    navigate(path);
  }

  return (
    <Container fluid={true}>
      <MainJumbotron />

      <CourseMobileCarrouselComponent courses={courses} />
      <Col md={8} xl={12} className="mx-auto">
        <Row>
          {courses.length > 0 && (
            <>
              <Col
                xl={3}
                className="mx-auto mt-3 mb-5 d-flex justify-content-center"
              >
                <SeeMoreCoursesButton
                  onClick={() => redirectTo('/cursos')}
                  className="btn btn-default all-courses"
                >
                  Ver todos os cursos
                </SeeMoreCoursesButton>
              </Col>
            </>
          )}
        </Row>
      </Col>

      <WhoWeAreComponent />
      <OurActivitiesComponent institutionalPageInfo={institutionalPageInfo} />
      <PartnersComponent />
      <WhatWeOfferComponent />
    </Container>
  );
}
