import { Card, Col, Row } from 'react-bootstrap';

import { H1Styles, H3GreenStyles } from '../../styles/GlobalStyles';
import {
  ExecutiveBoardComponentStyles,
  ExecutiveBoardCardStyles,
} from './styles';

interface ExecutiveBoardComponentProps {
  wpData: any;
}

export function ExecutiveBoardComponent({
  wpData,
}: ExecutiveBoardComponentProps) {
  const cardsExecutive: { title: string; position: string; image: string }[] =
    [];
  const cardsTrustees: { title: string; position: string; image: string }[] =
    [];
  const cardsFiscal: { title: string; image: string }[] =
    [];

  if (wpData) {
    wpData?.names_board_trustees?.map((item: any, index: number) => {
      return cardsTrustees.push({
        title: item,
        position: wpData?.positions_board_trustees[index],
        image:
          process.env.REACT_APP_WORDPRESS_API +
          wpData?.images_board_trustees[index]?.guid,
      });
    });
  }

  if (wpData) {
    wpData?.names_executive_board?.map((item: any, index: number) => {
      return cardsExecutive.push({
        title: item,
        position: wpData?.positions_executive_board[index],
        image:
          process.env.REACT_APP_WORDPRESS_API +
          wpData?.images_executive_board[index]?.guid,
      });
    });
  }

  if (wpData) {
    wpData?.name_board_fiscal?.map((item: any, index: number) => {
      return cardsFiscal.push({
        title: item,       
        image:
          process.env.REACT_APP_WORDPRESS_API +
          wpData?.images_board_fiscal[index]?.guid,
      });
    });
  }

  return (
    <ExecutiveBoardComponentStyles>
      <Col md={10} xl={9} className="mx-auto">
        <Row>
          <Col>
            <Row>
              <Col>
                <H1Styles className="mb-2">
                  {wpData?.title_executive_board}
                </H1Styles>
              </Col>
            </Row>
            <Row>
              <Col>
                <H3GreenStyles
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: wpData?.description_executive_board,
                  }}
                />
              </Col>
            </Row>

            <Row className="justify-content-center">
              {cardsExecutive?.map((item: any) => {
                return (
                  <Col md={6} xl={4}>
                    <ExecutiveBoardCardStyles>
                      <Card.Img variant="top" src={item.image} />
                      <Card.Body className="text-center my-3">
                        <Card.Title>{item.title}</Card.Title>
                        <Card.Subtitle>{item.position}</Card.Subtitle>
                      </Card.Body>
                    </ExecutiveBoardCardStyles>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>

        <Row className="mt-4 mt-xl-5">
          <Col>
            <Row>
              <Col>
                <H1Styles className="mb-2">
                  {wpData?.title_board_trustees}
                </H1Styles>
              </Col>
            </Row>
            <Row>
              <Col>
                <H3GreenStyles
                  className="mb-5"
                  dangerouslySetInnerHTML={{
                    __html: wpData?.description_board_trustees,
                  }}
                />
              </Col>
            </Row>

            <Row className="justify-content-center">
              {cardsTrustees?.map((item: any) => {
                return (
                  <Col md={6} xl={4}>
                    <ExecutiveBoardCardStyles>
                      <Card.Img variant="top" src={item.image} />
                      <Card.Body className="text-center my-3">
                        <Card.Title>{item.title}</Card.Title>
                        <Card.Subtitle>{item.position}</Card.Subtitle>
                      </Card.Body>
                    </ExecutiveBoardCardStyles>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
        <Row className="mt-4 mt-xl-5">
          <Col>
            <Row>
              <Col>
                <H1Styles className="mb-2">
                  {wpData?.title_board_fiscal}
                </H1Styles>
              </Col>
            </Row>
            <Row>
              <Col>
                <H3GreenStyles
                  className="mb-4"
                  dangerouslySetInnerHTML={{
                    __html: wpData?.description_board_fiscal,
                  }}
                />
              </Col>
            </Row>
            <Row className="justify-content-center mt-3">
            {cardsFiscal?.map((item: any) => {
                return (
                  <Col md={6} xl={4}>
                    <ExecutiveBoardCardStyles>
                      <Card.Img variant="top" src={item.image} />
                      <Card.Body className="text-center my-3">
                        <Card.Title>{item.title}</Card.Title>                        
                      </Card.Body>
                    </ExecutiveBoardCardStyles>
                  </Col>
                );
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </ExecutiveBoardComponentStyles>
  );
}
