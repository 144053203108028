import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { H1Styles, PartnerImg } from '../../styles/GlobalStyles';
import logUnimed from '../../assets/image/unimed-campo-grande.png';


export function PartnersComponent() {
  return (
    <Row className="mb-5">
      <Col xl={9} className="mx-auto mt-5 mt-8">
        <Row>
          <Col>
            <H1Styles className="mb-4">Parceiros</H1Styles>
            <Row className="d-flex justify-content-center">
              <Col lg={8} className="d-flex justify-content-center">                
                <PartnerImg src={logUnimed} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}